/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Formik } from 'formik';
import Button from '@fify.at/gatsby-theme-base/src/components/Button';
import React, { useEffect, useState } from 'react';

export default function Wizard({
  children,
  initialValues,
  isLastPage,
  isSecondToLastPage,
  next,
  onSubmit,
  page,
  previous,
  validate,
}) {
  const handleSubmit = (values, bag) => {
    if (isLastPage) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      next(values);
    }
  };

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const fields = Object.keys(initialValues);

  return (
    <Formik
      initialValues={{
        'form-name': 'immobilienAnkaufAnfrage',
        ...initialValues,
      }}
      enableReinitialize={false}
      validate={validate}
      onSubmit={handleSubmit}
      render={({ values, handleSubmit, isSubmitting, handleReset }) => (
        <form
          onSubmit={handleSubmit}
          name="immobilienAnkaufAnfrage"
          data-netlify="true"
        >
          {fields.map(field => (
            <input name={field} hidden />
          ))}
          <div
            sx={{
              display: 'grid',
              gridAutoColumns: '100%',
              gridTemplateRows: '1fr',
              gridAutoFlow: 'column',
              overflow: 'hidden',
              position: 'relative',
              marginX: -3,
              '>*': {
                paddingX: 3,
              },
            }}
          >
            {children}
          </div>
          <div
            sx={{
              display: 'grid',
              gridTemplateColumns: [
                '1fr',
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr 1fr 1fr 1fr 1fr',
              ],
              gap: 3,
              marginTop: 6,
            }}
          >
            {page > 0 && (
              <Button
                type="button"
                onClick={() => previous(values)}
                sx={{
                  gridColumn: [
                    '1 /span 1',
                    '1 /span 1',
                    '1 /span 1',
                    '1 /span 1',
                    '2 / span 2',
                  ],
                }}
              >
                <i class="fal fa-arrow-left"></i> Vorhergehender Schritt
              </Button>
            )}

            {!isLastPage && !isSecondToLastPage && (
              <Button
                type="submit"
                sx={{
                  gridColumn: [
                    'span 1',
                    '2 / span 1',
                    '2 /span 1',
                    '2 /span 1',
                    '4 / span 2',
                  ],
                }}
              >
                Nächster Schritt <i class="fal fa-arrow-right"></i>
              </Button>
            )}

            {isSecondToLastPage && (
              <Button
                type="submit"
                sx={{
                  gridColumn: [
                    'span 1',
                    '2 / span 1',
                    '2 /span 1',
                    '2 /span 1',
                    '4 / span 2',
                  ],
                }}
              >
                Ergebnis zuschicken <i class="fal fa-arrow-right"></i>
              </Button>
            )}

            {isLastPage && mounted && (
              <Button
                type="submit"
                disabled={isSubmitting}
                sx={{
                  gridColumn: 'span 2',
                }}
                onClick={function() {
                  if (typeof fbq !== 'undefined') {
                    fbq('track', 'Lead');
                  }
                }}
              >
                Jetzt Anfrage absenden <i class="fal fa-arrow-right"></i>
              </Button>
            )}
          </div>
        </form>
      )}
    />
  );
}
