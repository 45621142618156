/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { IntlProvider, FormattedNumber } from 'react-intl';

export default function NumberFormat({
  value,
  currency = 'EUR',
  locale,
  style = 'currency',
  currencyDisplay = 'code',
}) {
  return (
    <div>
      <IntlProvider locale={locale}>
        <FormattedNumber
          value={value}
          locale={locale}
          style={style}
          currency={currency}
          currencyDisplay={currencyDisplay}
        />
      </IntlProvider>
    </div>
  );
}
