/** @jsx jsx */
import { TextField } from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { Component } from 'react';
import { jsx } from 'theme-ui';
import { useFormikContext, useField, ErrorMessage, Field } from 'formik';
import WizardPriceData from './WizardPriceData';
import NumberFormat from '../NumberFormat';
import React, { useEffect } from 'react';

export default function WizardResult({ className }) {
  useEffect(() => {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'Produkt anpassen');
    }
  }, []);

  const { values } = useFormikContext();

  const [plotPriceValue, , plotPriceHelpers] = useField('plotPrice');
  const [cellarPriceValue, , cellarPriceHelpers] = useField('cellarPrice');
  const [buildingPriceValue, , buildingPriceHelpers] = useField(
    'buildingPrice'
  );
  const [suggestedPriceValue, , suggestedPriceHelpers] = useField(
    'suggestedPrice'
  );
  /**
   * Calculate the price of the plot
   */
  let pricePerUnitOfPlot = 0;
  const cityData = WizardPriceData.filter(
    entry => entry.postleitzahl == values.postalCode && entry.ort == values.city
  );

  if (cityData && cityData[0] && cityData[0].preis > 0) {
    pricePerUnitOfPlot = cityData[0].preis;
  } else {
    pricePerUnitOfPlot = values.guessedPlotPrice;
  }

  let locationQualityMultiplier = 1;
  if (values.locationQuality === '1') {
    locationQualityMultiplier = 1.2;
  } else if (values.locationQuality === '3') {
    locationQualityMultiplier = 0.8;
  }

  let locationDisadvantagesMultiplier = 1;
  if (values.locationDisadvantages === 'true') {
    locationDisadvantagesMultiplier = 0.8;
  }

  let plotPrice = Math.floor(
    values.plotArea *
      pricePerUnitOfPlot *
      locationQualityMultiplier *
      locationDisadvantagesMultiplier
  );

  /**
   * Calcualte the price of the cellar
   */

  let cellarPrice = 0;

  if (values.cellarAvailable === 'true' && values.propertyType === 'house') {
    let cellarPriceIndex = 0;
    let cellarUsableForYears = 0;

    if (values.cellarType === '1') {
      cellarPriceIndex = 600;
      cellarUsableForYears = 80;
    } else if (values.cellarType === '2') {
      cellarPriceIndex = 800;
      cellarUsableForYears = 70;
    } else if (values.cellarType === '3') {
      cellarPriceIndex = 1000;
      cellarUsableForYears = 60;
    } else if (values.cellarType === '4') {
      cellarPriceIndex = 1500;
      cellarUsableForYears = 60;
    }

    const preliminaryCellarPrice = values.cellarSpace * cellarPriceIndex;

    // If value 0 we need to ask if its a "Abbruchobjekt" and if yes go to Sanierungs part
    const cellarRemainingYears = Math.max(
      0,
      cellarUsableForYears - (values.currentYear - values.cellarBuildYear)
    );

    // This '80' should probably be cellarUsableForYears?
    cellarPrice = Math.floor(
      (preliminaryCellarPrice / cellarUsableForYears) * cellarRemainingYears
    );
  }

  /**
   * Calculate the price of the building
   */

  let buildingPrice = 0;

  if (values.propertyType === 'house') {
    let buildingPriceIndex = 0;
    let buildingUsableForYears = 0;

    if (values.houseFeatureSet === '1') {
      buildingPriceIndex = 1300;
      buildingUsableForYears = 100;
    } else if (values.houseFeatureSet === '2') {
      buildingPriceIndex = 1600;
      buildingUsableForYears = 80;
    } else if (values.houseFeatureSet === '3') {
      buildingPriceIndex = 1900;
      buildingUsableForYears = 70;
    } else if (values.houseFeatureSet === '4') {
      buildingPriceIndex = 2200;
      buildingUsableForYears = 60;
    }

    let fictitiousYearOfConstruction = values.yearOfConstruction;
    if (values.renovated && values.renovationYear > values.yearOfConstruction) {
      let renovationGrade = 0;

      if (values.renovationGrade === '100%') {
        renovationGrade = 1;
      } else if (values.renovationGrade === '75%') {
        renovationGrade = 0.75;
      } else if (values.renovationGrade === '50%') {
        renovationGrade = 0.5;
      } else if (values.renovationGrade === '25%') {
        renovationGrade = 0.25;
      }

      const calculatedYearOfConstruction =
        values.yearOfConstruction +
        (values.renovationYear - 10 - values.yearOfConstruction) *
          renovationGrade;

      if (calculatedYearOfConstruction > fictitiousYearOfConstruction) {
        fictitiousYearOfConstruction = calculatedYearOfConstruction;
      }
    }

    const preliminaryBuildingPrice = values.livingArea * buildingPriceIndex;

    const buildingRemainingYears = Math.max(
      0,
      buildingUsableForYears -
        (values.currentYear - fictitiousYearOfConstruction)
    );

    buildingPrice = Math.floor(
      (preliminaryBuildingPrice / buildingUsableForYears) *
        buildingRemainingYears
    );
  }

  /**
   * Calculate the price of the entire "package"
   */

  let preliminaryPrice = plotPrice + cellarPrice + buildingPrice;

  let locationMultiplier = 1;

  if (pricePerUnitOfPlot < 25) {
    locationMultiplier = 1; // Temporarily disabled 0.5;
  } else if (pricePerUnitOfPlot < 50) {
    locationMultiplier = 1; // Temporarily disabled 0.8;
  } else if (pricePerUnitOfPlot < 200) {
    locationMultiplier = 1;
  } else {
    locationMultiplier = 1.2;
  }

  let urbanMultiplier = 1;

  if (values.locationQuality === '1') {
    urbanMultiplier = 1.05;
  } else if (values.locationQuality === '2') {
    urbanMultiplier = 1;
  } else if (values.locationQuality === '3') {
    urbanMultiplier = 0.95;
  }

  let disadvantagesMultiplier = 1;

  if (values.locationDisadvantages === 'true') {
    disadvantagesMultiplier = 0.9;
  }

  let suggestedPrice = Math.floor(
    preliminaryPrice *
      locationMultiplier *
      urbanMultiplier *
      disadvantagesMultiplier
  );

  if (!isNaN(plotPrice) && plotPriceValue.value !== plotPrice) {
    plotPriceHelpers.setValue(plotPrice);
  }
  if (!isNaN(cellarPrice) && cellarPriceValue.value !== cellarPrice) {
    cellarPriceHelpers.setValue(cellarPrice);
  }
  if (!isNaN(buildingPrice) && buildingPriceValue.value !== buildingPrice) {
    buildingPriceHelpers.setValue(buildingPrice);
  }
  if (!isNaN(suggestedPrice) && suggestedPriceValue.value !== suggestedPrice) {
    suggestedPriceHelpers.setValue(suggestedPrice);
  }

  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'max-content',
        border: '1px solid #f6f6f6',
        backgroundColor: 'muted',
        borderRadius: '5px',
      }}
    >
      <h4 sx={{ marginBottom: 4, marginTop: 4, textAlign: 'center' }}>
        Das Ergebnis der Online Bewertung.
      </h4>
      <p>Ungefährer Verkehrswert (ohne Gewähr*)</p>
      <dl
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', 'auto 1fr'],
          columnGap: 3,

          dt: {
            textAlign: 'right',
          },
        }}
      >
        <dd>
          <h5>Grundstück</h5>
        </dd>
        <dt>
          <h5>
            <NumberFormat value={plotPrice} locale="de" />
          </h5>
        </dt>
        {cellarPrice > 0 && (
          <>
            <dd>
              <h5>Keller (Zeitwert)</h5>
            </dd>
            <dt>
              <h5>
                <NumberFormat value={cellarPrice} locale="de" />
              </h5>
            </dt>
          </>
        )}
        {buildingPrice > 0 && (
          <>
            <dd>
              <h5>Gebäude (Zeitwert)</h5>
            </dd>
            <dt>
              <h5>
                <NumberFormat value={buildingPrice} locale="de" />
              </h5>
            </dt>
          </>
        )}
        {/*<dd>Qualitätsmultiplikator</dd>*/}
        {/*<dt >{locationMultiplier}</dt>*/}
        {/*<dd>Gegendmultiplikator</dd>*/}
        {/*<dt >{urbanMultiplier}</dt>*/}
        {/*<dd>Störfaktorenmultiplikator</dd>*/}
        {/*<dt >{disadvantagesMultiplier}</dt>*/}
        <dd>
          <h4>Gesamt (Verkehrswert)</h4>
        </dd>
        <dt>
          <h4>
            <span sx={{ display: 'inline-block' }}>
              <NumberFormat value={suggestedPrice * 0.9} locale="de" />
            </span>{' '}
            -{' '}
            <span sx={{ display: 'inline-block' }}>
              <NumberFormat value={suggestedPrice * 1.1} locale="de" />
            </span>
          </h4>
        </dt>
      </dl>
      <input type="hidden" name="plotPrice" />
      <input type="hidden" name="cellarPrice" />
      <input type="hidden" name="buildingPrice" />
      <input type="hidden" name="suggestedPrice" />
      <input type="hidden" name="booklet" />
    </div>
  );
}
