/** @jsx jsx */
import {
  RadioButton,
  SelectField,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { Component } from 'react';
import { jsx } from 'theme-ui';
import { useFormikContext, ErrorMessage } from 'formik';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';

export default function WizardCellar({ className }) {
  const { values } = useFormikContext();

  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content 1fr',
        alignItems: 'center',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Hat die Immobilie einen Keller?
      </h4>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['130px 130px', '200px 200px'],
          gridTemplateRows: ['130px', '200px'],
          justifySelf: 'center',
        }}
      >
        <label
          sx={{
            backgroundColor: '#e5efff',
            padding: 5,
            margin: 2,
            border:
              values.cellarAvailable === 'true'
                ? '2px solid'
                : '2px solid white',
            borderColor: values.cellarAvailable === 'true' ? 'primary' : null,
            color: 'primary',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 300ms ease-in-out',
          }}
        >
          <Icon
            variant="fal"
            icon="fa-home"
            sx={{ fontSize: ['30px', '50px', '60px'] }}
          />
          <Icon
            variant="fal"
            icon="fa-rectangle-landscape"
            sx={{ fontSize: ['30px', '50px'], marginBottom: ['10px', '15px'] }}
          />
          <span>Ja</span>
          <RadioButton
            name="cellarAvailable"
            value="true"
            sx={{ marginRight: '5px' }}
            sx={{ opacity: 0, position: 'absolute' }}
          />
        </label>
        <label
          sx={{
            backgroundColor: '#e5efff',
            padding: 5,
            margin: 2,
            border:
              values.cellarAvailable === 'false'
                ? '2px solid'
                : '2px solid white',
            borderColor: values.cellarAvailable === 'false' ? 'primary' : null,
            color: 'primary',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 300ms ease-in-out',
          }}
        >
          <Icon
            variant="fal"
            icon="fa-home"
            sx={{
              fontSize: ['30px', '50px', '60px'],
              marginBottom: ['40px', '65px'],
            }}
          />
          <span>Nein</span>
          <RadioButton
            name="cellarAvailable"
            value="false"
            sx={{ marginRight: '5px' }}
            sx={{ opacity: 0, position: 'absolute' }}
          />
        </label>
      </div>
      <ErrorMessage
        name="cellarAvailable"
        component="div"
        className="field-error"
      />
    </div>
  );
}

export function validate(values) {
  const errors = {};
  if (!values.cellarAvailable) {
    errors.cellarAvailable =
      'Bitte sagen Sie uns, ob ein Keller bei der Immobilie dabei ist.';
  }
  return errors;
}
