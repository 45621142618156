/** @jsx jsx */
import {
  RadioButton,
  SelectField,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { Component } from 'react';
import { jsx } from 'theme-ui';
import { useFormikContext, ErrorMessage } from 'formik';

export default function WizardBuilding({ className }) {
  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'max-content',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Wie sieht das zu bewertende Haus aus?
      </h4>
      <TextField
        name="livingArea"
        component="input"
        placeholder="z.B. 120"
        type="Number"
        label="Wohnfläche in m² als Zahl"
      />
      <TextField
        name="yearOfConstruction"
        component="input"
        placeholder="z.B. 1987"
        type="Number"
        label="Baujahr des Hauses"
      />
      <label>Ausstattungsgrad</label>
      <SelectField name="houseFeatureSet" as="select">
        <option disabled selected value></option>
        <option value="1">Einfache gute Ausstattung</option>
        <option value="2">
          Erweiterte Ausstattung wie Zentralheizung, Bad, WC
        </option>
        <option value="3">
          Gehobene Ausstattung wie Wärmeschutz, Fußbodenheizung, moderne Fenster
        </option>
        <option value="4">
          Top Ausstattung, Passivhaus, Luxus, modernste Technik
        </option>
      </SelectField>
      <label>Wurde das Haus schon einmal saniert?</label>
      <label>
        <RadioButton
          type="radio"
          name="renovated"
          value="true"
          sx={{ marginRight: '5px' }}
        />
        Ja
      </label>
      <label>
        <RadioButton
          type="radio"
          name="renovated"
          value="false"
          sx={{ marginRight: '5px' }}
        />
        Nein
      </label>
      <ErrorMessage name="renovated" component="div" className="field-error" />
    </div>
  );
}

export function validate(values) {
  const errors = {};
  if (!values.livingArea) {
    errors.livingArea = 'Bitte geben Sie die Wohnfläche an.';
  }

  if (!values.yearOfConstruction) {
    errors.yearOfConstruction = 'Bitte geben Sie das Baujahr an.';
  }

  if (!values.houseFeatureSet) {
    errors.houseFeatureSet =
      'Bitte geben Sie die Ausstattungskategorie des Hauses an.';
  }

  if (!values.renovated) {
    errors.renovated = 'Bitte geben Sie an, ob das Haus renoviert wurde.';
  }

  return errors;
}
