/** @jsx jsx */
import {
  RadioButton,
  SelectField,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { Component } from 'react';
import { jsx } from 'theme-ui';
import { useFormikContext, ErrorMessage } from 'formik';

export default function WizardProperty({ className }) {
  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'max-content',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Beschreiben Sie bitte das Grundstück.
      </h4>
      <TextField
        name="plotArea"
        component="input"
        type="Number"
        label="Grundstücksfläche in m²"
      />
      <label>Qualität der Lage</label>
      <SelectField name="locationQuality">
        <option disabled selected value></option>
        <option value="1">Erstklassig Lage im oder Nahe dem Zentrum</option>
        <option value="2">Durchschnittliche aber gute Lage</option>
        <option value="3">Keine besondere Lage</option>
      </SelectField>
      <label>
        Bestehen Einschränkungen wie Straßenlärm, Industrieanlagen in der Nähe,
        Bauform etc.
      </label>
      <label>
        <RadioButton
          name="locationDisadvantages"
          value={'true'}
          sx={{ marginRight: '5px' }}
        />
        Ja
      </label>
      <label>
        <RadioButton
          name="locationDisadvantages"
          value={'false'}
          sx={{ marginRight: '5px' }}
        />
        Nein
      </label>
      <ErrorMessage
        name="locationDisadvantages"
        component="div"
        className="field-error"
      />
    </div>
  );
}

export function validate(values) {
  const errors = {};
  if (!values.plotArea) {
    errors.plotArea = 'Bitte geben Sie die Grundstücksfläche an.';
  }
  if (!values.locationQuality) {
    errors.locationQuality = 'Bitte schätzen Sie die Lage ihres Grundstücks.';
  }

  if (!values.locationDisadvantages) {
    errors.locationDisadvantages =
      'Bitte geben Sie an, ob es äußere Störfaktoren gibt.';
  }
  return errors;
}
