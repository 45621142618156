/** @jsx jsx */
import { TextField } from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { jsx } from 'theme-ui';
import SelectField from '@fify.at/gatsby-theme-base/src/components/Form/SelectField';
import { useFormikContext } from 'formik';
import WizardZipcodeData from './WizardZipcodeData';

export default function WizardAddress({ className }) {
  const { values } = useFormikContext();

  const cities = WizardZipcodeData.filter(
    entry => entry.postleitzahl == values.postalCode
  );

  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'max-content',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Wo befindet sich die Immobilie, die bewertet werden soll?
      </h4>
      <p>
        Um die Preislage zu ermitteln, benötigen wir den Standort der Immobilie.
      </p>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          gridColumnGap: 3,
          gridAutoRows: 'max-content',
        }}
      >
        <div
          sx={{
            gridColumn: ['span 1', 'span 1', 'span 2'],
          }}
        >
          <TextField
            name="streetAndNumber"
            component="input"
            type="Text"
            label="Straße / Hausnummer"
          />
        </div>
        <TextField
          name="postalCode"
          component="input"
          type="Number"
          label="Postleitzahl"
        />
        {cities && cities[0] ? (
          <SelectField name="city" label="Stadt">
            <option></option>
            {cities[0].orte.map(city => (
              <option>{city}</option>
            ))}
          </SelectField>
        ) : (
          <TextField name="city" component="input" type="Text" label="Stadt" />
        )}
      </div>
    </div>
  );
}

export function validate(values) {
  const errors = {};
  if (!values.streetAndNumber) {
    errors.streetAndNumber =
      'Bitte geben Sie die Straße und Hausnummer der Immobilie an.';
  }
  if (!values.postalCode) {
    errors.postalCode = 'Bitte geben Sie die Postleitzahl der Immobilie an.';
  }
  if (!values.city) {
    errors.city = 'Bitte geben Sie die Stadt oder den Ort der Immobilie an.';
  }
  return errors;
}
