/** @jsx jsx */
import Alert from '@fify.at/gatsby-theme-base/src/components/Alert/Alert';
import { handleNetlifySubmit } from '@fify.at/gatsby-theme-base/src/components/Form';
import React from 'react';
import { jsx } from 'theme-ui';
import Wizard from './Wizard';
import WizardAddress, { validate as validateAddress } from './WizardAddress';
import WizardGuessPrice, {
  validate as validatePrice,
} from './WizardGuessPrice';
import WizardConstructionYear, {
  validate as validateConstructionYear,
} from './WizardConstructionYear';
import WizardContactInformation, {
  validate as validateContactInforation,
} from './WizardContactInformation';
import WizardProperty, { validate as validateProperty } from './WizardProperty';
// import { Debug } from './Debug';
import WizardPropertyType, {
  validate as validatePropertyType,
} from './WizardPropertyType';
import WizardCellar, { validate as validateCellar } from './WizardCellar';
import WizardCellarDetails, {
  validate as validateCellarDetails,
} from './WizardCellarDetails';
import WizardBuilding, { validate as validateBuilding } from './WizardBuilding';
import WizardBuildingRenovation, {
  validate as validateBuildingRenovation,
} from './WizardBuildingRenovation';
import WizardResult from './WizardResult';
import WizardMissingData from './WizardMissingData';
import WizardPriceData from './WizardPriceData';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';
import { Location } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

function ListingBuyCalculator({ location }) {
  const data = useStaticQuery(graphql`
    {
      booklet: file(
        name: {
          eq: "WirkaufendeinHaus - Die 10 häufigsten Fehler beim Hausverkauf"
        }
      ) {
        publicURL
      }
    }
  `);

  const booklet = data.booklet;

  const [isSubmitted, setSubmitted] = React.useState(false);
  const [page, setPage] = React.useState(0);

  if (isSubmitted) {
    return (
      <Alert variant="success">
        Vielen Dank für Ihre Anfrage! Sie erhalten von uns in Kürze die
        angeforderten Informationen per E-Mail übermittelt. Sollten Sie Fragen
        oder Anregungen haben, können Sie jederzeit gerne mit uns{' '}
        <Link to="/kontakt/">Kontakt</Link> aufnehmen.
      </Alert>
    );
  }

  const now = new Date();
  const currentYear = now.getUTCFullYear();

  const stepsReference = [];

  const validate = values => {
    const activePage = stepsReference[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  const next = values => {
    let nextPage = page;

    while (nextPage < stepsReference.length) {
      nextPage = nextPage + 1;
      const page = stepsReference[nextPage];
      const skip = page.props.skipMe(values);
      if (!skip) {
        break;
      }
    }
    setPage(nextPage);
  };

  const previous = values => {
    let previousPage = page;

    while (0 < previousPage) {
      previousPage = previousPage - 1;
      const page = stepsReference[previousPage];
      const skip = page.props.skipMe(values);
      if (!skip) {
        break;
      }
    }
    setPage(previousPage);
  };

  const steps = [
    <WizardPropertyType
      validate={validatePropertyType}
      skipMe={() => false}
      next={next}
    />,
    <WizardAddress validate={validateAddress} skipMe={() => false} />,
    <WizardGuessPrice
      validate={validatePrice}
      skipMe={values => {
        const cityData = WizardPriceData.filter(
          entry =>
            entry.postleitzahl == values.postalCode && entry.ort == values.city
        );
        return cityData && cityData[0] && cityData[0].preis;
      }}
    />,
    <WizardProperty validate={validateProperty} skipMe={() => false} />,
    <WizardCellar
      validate={validateCellar}
      skipMe={values => values.propertyType === 'plot'}
    />,
    <WizardCellarDetails
      validate={validateCellarDetails}
      skipMe={values =>
        values.cellarAvailable === 'false' || values.propertyType === 'plot'
      }
    />,
    <WizardBuilding
      validate={validateBuilding}
      skipMe={values => values.propertyType === 'plot'}
    />,
    <WizardBuildingRenovation
      validate={validateBuildingRenovation}
      skipMe={values =>
        values.renovated === 'false' || values.propertyType === 'plot'
      }
    />,
    <WizardResult skipMe={() => false} />,
    // <WizardMissingData
    //   skipMe={values => {
    //     const cityData = WizardData.filter(
    //       entry => entry.postleitzahl == values.postalCode
    //     );
    //     return cityData && cityData[0] && cityData[0].preis;
    //   }}
    // />,
    <WizardContactInformation
      validate={validateContactInforation}
      skipMe={() => false}
    />,
  ];

  steps.map(s => stepsReference.push(s));

  return (
    <div className="App">
      <Wizard
        name="real-estate-valuation"
        initialValues={{
          booklet: location.origin + encodeURI(booklet.publicURL),

          email: undefined,

          currentYear: currentYear,
          propertyType: undefined,

          postalCode: undefined,
          plotArea: undefined,
          locationQuality: undefined,
          locationDisadvantages: undefined,

          cellarAvailable: undefined,
          cellarSpace: undefined,
          cellarBuildYear: undefined,
          cellarType: undefined,

          livingArea: undefined,
          yearOfConstruction: undefined,
          renovated: undefined,
          renovationGrade: undefined,
          renovationYear: undefined,
          houseFeatureSet: undefined,

          plotPrice: undefined,
          guessedPlotPrice: undefined,
          cellarPrice: undefined,
          buildingPrice: undefined,
          suggestedPrice: undefined,

          followUpTypes: undefined,
          streetAndNumber: undefined,
          city: undefined,
          country: undefined,
          firstName: undefined,
          lastName: undefined,
          phone: undefined,
        }}
        onSubmit={(values, actions) => {
          sleep(300).then(() => {
            handleNetlifySubmit(
              values,
              actions,
              window.location.pathname
            ).then(() => setSubmitted(true));
          });
        }}
        isLastPage={page === steps.length - 1}
        isSecondToLastPage={page === steps.length - 2}
        next={next}
        page={page}
        previous={previous}
        validate={validate}
      >
        {steps[page]}
      </Wizard>
    </div>
  );
}

export default props => (
  <Location>
    {({ location }) => <ListingBuyCalculator location={location} {...props} />}
  </Location>
);
