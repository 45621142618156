/** @jsx jsx */
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import { ErrorMessage, Field } from 'formik';
import { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { useFormikContext } from 'formik';
import {
  RadioButton,
  SelectField,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';

export default function WizardPropertyType({ className, next }) {
  const { values } = useFormikContext();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!values.propertyType) {
      setMounted(true);
    }
  }, [values.propertyType]);

  if (values.propertyType && mounted) {
    next();
  }

  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content 1fr',
        alignItems: 'center',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Welche Immobilie möchten Sie bewerten?
      </h4>

      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['130px 130px', '200px 200px'],
          gridTemplateRows: ['130px', '200px'],
          justifySelf: 'center',
        }}
      >
        <label
          sx={{
            backgroundColor: '#e5efff',
            padding: 5,
            margin: 2,
            border:
              values.propertyType === 'house' ? '2px solid' : '2px solid white',
            borderColor: values.propertyType === 'house' ? 'primary' : null,
            color: 'primary',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 300ms ease-in-out',
          }}
        >
          <Icon
            variant="fal"
            icon="fa-home"
            sx={{ fontSize: ['30px', '50px', '60px'], marginBottom: [1, 3] }}
          />
          <span>Haus</span>
          <RadioButton
            name="propertyType"
            value="house"
            sx={{ opacity: 0, position: 'absolute' }}
          />
        </label>
        <label
          sx={{
            backgroundColor: '#e5efff',
            padding: 5,
            margin: 2,
            border:
              values.propertyType === 'plot' ? '2px solid' : '2px solid white',
            borderColor: values.propertyType === 'plot' ? 'primary' : null,
            color: 'primary',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 300ms ease-in-out',
          }}
        >
          <Icon
            variant="fal"
            icon="fa-square"
            sx={{ fontSize: ['30px', '50px', '60px'], marginBottom: [1, 3] }}
          />
          <span>Grundstück</span>
          <RadioButton
            name="propertyType"
            value="plot"
            sx={{ opacity: 0, position: 'absolute' }}
          />
        </label>
      </div>
      <ErrorMessage
        name="propertyType"
        component="div"
        className="field-error"
      />
    </div>
  );
}

export function validate(values) {
  const errors = {};
  if (!values.propertyType) {
    errors.propertyType = 'Bitte wählen Sie eine Objektart aus.';
  }
  return errors;
}
