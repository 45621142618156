/** @jsx jsx */
import {
  RadioButton,
  SelectField,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { Component } from 'react';
import { jsx } from 'theme-ui';
import { useFormikContext, ErrorMessage } from 'formik';

export default function WizardCellarDetails({ className }) {
  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'max-content',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Beschreiben Sie bitte den Keller der Immobilie.
      </h4>
      <TextField
        name="cellarSpace"
        component="input"
        type="Number"
        placeholder="z.B. 90"
        label="Kellerfläche in m² als Zahl"
      />
      <TextField
        name="cellarBuildYear"
        component="input"
        placeholder="z.B. 1976"
        type="Number"
        label="Baujahr des Kellers"
      />
      <label>Wie wurde der Keller ausgebaut?</label>
      <SelectField name="cellarType">
        <option disabled selected value></option>
        <option value="1">Nackter Keller</option>
        <option value="2">Verputzt, Böden, Strom</option>
        <option value="3">Ausgebaut und beheizt</option>
        <option value="4">Vollwertig bewohnbar</option>
      </SelectField>
    </div>
  );
}

export function validate(values) {
  const errors = {};

  if (!values.cellarSpace) {
    errors.cellarSpace = 'Bitte geben Sie die Kellerfläche an.';
  }
  if (!values.cellarBuildYear) {
    errors.cellarBuildYear = 'Bitte geben Sie das Baujahr des Kellers an.';
  }
  if (!values.cellarType) {
    errors.cellarType =
      'Bitte geben Sie die Ausstattungskategorie des Kellers an.';
  }
  return errors;
}
