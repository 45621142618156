/** @jsx jsx */
import {
  Checkbox,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { Component } from 'react';
import { jsx } from 'theme-ui';

export default function WizardContactInformation({ className }) {
  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'max-content',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Für die Zusendung der Ergebnisse benötigen wir Ihre Kontaktdaten.
      </h4>
      <p>
        Als kleines Dankeschön erhalten Sie auch unser kostenloses Booklet "Die
        10 häufigsten Fehler beim Hausverkauf" als Download Link.
      </p>
      <Row>
        <Col sm={12} lg={6}>
          <TextField
            name="firstName"
            component="input"
            type="Text"
            label="Vorname"
          />
        </Col>
        <Col sm={12} lg={6}>
          <TextField
            name="lastName"
            component="input"
            type="Text"
            label="Nachname"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <TextField
            name="phone"
            component="input"
            type="Text"
            label="Telefon"
          />
        </Col>
        <Col sm={12} lg={6}>
          <TextField
            name="email"
            component="input"
            type="Text"
            label="E-Mail"
          />
        </Col>
      </Row>
      <Checkbox
        label="Bitte kontaktieren Sie mich für ein persönliches Gespräch."
        name="followUpTypes"
        value="contact-me"
      />
    </div>
  );
}

export function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Bitte geben Sie Ihre E-Mail Adresse an.';
  }
  // if (!values.phone) {
  //   errors.phone = 'Bitte geben Sie Ihre Telefonnummer an.';
  // }
  if (!values.firstName) {
    errors.firstName = 'Bitte geben Sie Ihren Vornamen an.';
  }
  if (!values.lastName) {
    errors.lastName = 'Bitte geben Sie Ihren Nachnamen an.';
  }
  return errors;
}
