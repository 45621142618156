/** @jsx jsx */
import {
  RadioButton,
  SelectField,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { Component } from 'react';
import { jsx } from 'theme-ui';
import { useFormikContext, ErrorMessage } from 'formik';

export default function WizardBuildingRenovation({ className }) {
  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'max-content',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Bitte erzählen Sie uns von der Sanierung.
      </h4>
      <label>Sanierungsgrad</label>
      <SelectField name="renovationGrade" as="select">
        <option disabled selected value></option>
        <option value="100%">Generalsaniert (Alles ist neu)</option>
        <option value="75%">Ca. 75% Umfassende Sanierung</option>
        <option value="50%">Ca. 50% des Hauses eneuert</option>
        <option value="25%">Ca. 25% des Hauses eneuert</option>
        <option value="5%">Neu ausgemalt, kleinere Reparturen</option>
      </SelectField>
      <ErrorMessage
        name="renovationGrade"
        component="div"
        className="field-error"
      />
      <TextField
        name="renovationYear"
        component="input"
        type="Number"
        label="Sanierungsjahr"
      />
    </div>
  );
}

export function validate(values) {
  const errors = {};

  if (!values.renovationGrade) {
    errors.renovationGrade = 'Bitte geben Sie den Sanierungsgrad an.';
  }
  if (!values.renovationYear) {
    errors.renovationYear = 'Bitte geben Sie das Sanierungsjahr an.';
  }

  return errors;
}
