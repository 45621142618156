/** @jsx jsx */
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import { jsx } from 'theme-ui';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import ListingBuyCalculator from '../ListingBuyCalculator/ListingBuyCalculator';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';

export default function ValuationPlugin(props) {
  return (
    <Section
      {...props}
      sx={{
        maxWidth: [null, null, 500, 760, 1100],
        marginX: 'auto',
        boxShadow: [null, null, `0 0 30px rgba(0,0,0,0.1)`],
        paddingTop: [0, 0, 7],
        marginTop: [0, 0, 0],
        // outline: '1px solid red'
      }}
    >
      <Heading
        sx={{ color: 'primary', fontWeight: 'bold', textAlign: 'center' }}
      >
        Bewerten Sie Ihre Immobilie jetzt online!
      </Heading>
      <h4 sx={{ textAlign: 'center' }}>
        Ohne Registrierung, völlig anonym und kostenlos.
      </h4>
      <section sx={{ paddingTop: 6 }}>
        <div
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr 1fr'],
            gap: [3, 3, 3, 5],
          }}
        >
          <div>
            <div sx={{ textAlign: 'center' }}>
              <Icon
                sx={{ color: 'primary', marginBottom: 4 }}
                variant="fal fa-4x"
                icon="fa-clipboard-list fa-4x"
              />
            </div>
            <p>
              <strong>1 – Eckdaten Ihres Hauses bequem online eingeben</strong>{' '}
              <br />
              Geben Sie die ersten, relevanten Eckdaten zu Ihrer Immobilie in
              unser Online-Formular ein, um die Bewertung zu starten.
            </p>
          </div>
          <div>
            <div sx={{ textAlign: 'center' }}>
              <Icon
                sx={{ color: 'primary', marginBottom: 4 }}
                variant="fal fa-4x"
                icon="fa-envelope-open-text  fa-4x"
              />
            </div>
            <p>
              <strong>2 - Professionelle Wertindikation Ihrer Immobilie</strong>{' '}
              <br />
              Ihr Haus wird im Sachwertverfahren nach den Formeln der
              gerichtlichen Schätzer bewertet – natürlich in vereinfachter Form.
            </p>
          </div>
          <div>
            <div sx={{ textAlign: 'center' }}>
              <Icon
                sx={{ color: 'primary', marginBottom: 4 }}
                variant="fal"
                icon="fa-smile-beam fa-4x"
              />
            </div>
            <p>
              <strong>3 - Immobilienbewertung kostenlos auf einen Klick</strong>{' '}
              <br />
              Im Anschluss erhalten Sie auf Knopfdruck einen ersten Anhaltspunkt
              über den Wert Ihres Hauses.
            </p>
          </div>
        </div>
      </section>
      <ListingBuyCalculator />
      <section sx={{ fontStyle: 'italic', paddingTop: 3 }}>
        <small>
          * Bitte beachten Sie, dass es sich bei der Online Immobilienbewertung
          nur um einen Richtpreis handelt und keine Besichtigung und Bewertung
          durch einen Immobilienprofi vor Ort ersetzen kann. Bei einer Bewertung
          durch einen Immobilienprofi vor Ort kann es zu einer Abweichung des
          Richtpreises kommen, da hier natürlich wesentlich mehr Faktoren zur
          Bewertung miteinfließen.
        </small>
        <br />
        <br />
        <small>
          Da uns <Link to="/datenschutzerklaerung/">Datenschutz</Link> besonders
          wichtig ist, werden Ihre Daten von uns selbstverständlich vertraulich
          behandelt.
        </small>
      </section>
    </Section>
  );
}
