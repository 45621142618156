/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { jsx } from 'theme-ui';
import ValuationPlugin from '../../components/IntroSection/ValuationPlugin';
import Text_calltoaction from '../../components/raw_templates/Text_calltoaction';
import React from 'react';
import { graphql, Link } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Text_Image from '../../components/raw_templates/Text_Image';

const OnlineImmobilienbewertung = ({ location, data }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>
        {modal ? (
          <div sx={{ position: 'relative' }}>
            <Link
              sx={{
                position: 'sticky',
                float: 'right',
                top: 0,
                zIndex: 3,
                color: 'primary',
              }}
              to={closeTo}
            >
              <i className="fad fa-window-close fa-2x"></i>
            </Link>
            <Content data={data} />
          </div>
        ) : (
          <HomePageLayout
            location={location}
            lang="de"
            title="Kostenlose Online Immobilienbewertung für Ihr Haus"
            description="Nutzen Sie zur Erstorientierung die kostenlose Online Immobilienbewertung von WirkaufendeinHaus.at und finden Sie schnell und einfach heraus, wie der Wert Ihres Haus am Immobilienmarkt eingeschätzt wird."
            image={data.hero.childImageSharp.fluid}
            keywords={['Online', 'Immobilienbewertung']}
            header={{
              attachment: 'default',
              appearance: 'default',
              fluid: true,
            }}
          >
            <Content data={data} />
          </HomePageLayout>
        )}
      </div>
    )}
  </ModalRoutingContext.Consumer>
);

const Content = ({ data }) => (
  <>
    {/*<HeroSectionContainer*/}
    {/*  fluid={false}*/}
    {/*  orientation="southEast"*/}
    {/*  variant="alpha"*/}
    {/*  title="Online Immobilienbewertung"*/}
    {/*  image={data.hero}*/}
    {/*/>*/}

    <Text_Image
      sx={{
        paddingBottom: [0, 0, 7],
      }}
      background="white"
      image={data.hero}
      title="Mit nur wenigen Klicks den Wert Ihres Hauses berechnen"
      subtitle=""
      text="<p>Nutzen Sie zur Erstorientierung die kostenlose Online
        Immobilienbewertung von WirkaufendeinHaus.at und finden Sie schnell und
        einfach heraus, wie der Wert Ihres Haus am Immobilienmarkt eingeschätzt
        wird – kostenlos und ohne Registrierung! Unser Immobilienbewertungsrechner verschafft Ihnen im Handumdrehen
        einen ersten Anhaltspunkt über den Wert Ihres Hauses. Dabei wird Ihr Haus im Sachwertverfahren nach den Formeln der
gerichtlichen Schätzer bewertet – natürlich in vereinfachter Form.. Je mehr Informationen über Ihr Haus zur Verfügung stehen,
        desto präziser wird das Ergebnis der Bewertung ausfallen.
        "
    />

    <Section container="small" sx={{ paddingX: 0, paddingTop: [0, 0, 7] }}>
      <ValuationPlugin />
    </Section>

    <Text_calltoaction
      sectionBackground="muted"
      title="Sie möchten wissen, welchen Kaufpreis wir Ihnen für Ihre Haus anbieten?"
      subtitle="Dann vereinbaren Sie jetzt einen kostenlosen und unverbindlichen Beratungstermin!"
      text="<p>
      Unsere regionalen Immobilienexperten stehen Ihnen auf Wunsch gerne in einem
      persönlichen Besichtigungstermin vor Ort beratend zur Seite.
      Verlassen Sie sich dabei auf unseren durch jahrelanger Erfahrung geschulten Blick
      und erhalten Sie ein für uns verbindliches, maßgeschneidertes Angebot
      für Ihr Haus - Sie entscheiden dann, ob Sie es annehmen möchten oder nicht.
      </p>"
      buttons={[
        {
          text: 'Jetzt Termin vereinbaren <i class="fal fa-arrow-right"></i>',
          to: '/terminbuchung/',
        },
      ]}
    />
  </>
);

export default OnlineImmobilienbewertung;

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "echte-online-immobilienrechner" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
