/** @jsx jsx */
import { TextField } from '@fify.at/gatsby-theme-base/src/components/Form';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { jsx } from 'theme-ui';
import SelectField from '@fify.at/gatsby-theme-base/src/components/Form/SelectField';

export default function WizardGuessPrice({ className }) {
  return (
    <div
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'max-content',
      }}
    >
      <h4 sx={{ marginBottom: 4, textAlign: 'center' }}>
        Keine Preisinformationen vorhanden
      </h4>
      <p>
        Um die Preislage zu ermitteln, benötigen wir von Ihnen einen geschätzten
        Grundstücksprei / m<sup>2</sup>.
      </p>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr'],
          gridColumnGap: 3,
          gridAutoRows: 'max-content',
        }}
      >
        <TextField
          name="guessedPlotPrice"
          component="input"
          placeholder="z.B. 90"
          type="Number"
          label={
            <span>
              Geschätzter Gründstückspreis pro m<sup>2</sup> in &euro;
            </span>
          }
        />
      </div>
    </div>
  );
}

export function validate(values) {
  const errors = {};
  if (!values.guessedPlotPrice || values.guessedPlotPrice <= 0) {
    errors.guessedPlotPrice =
      'Bitte geben Sie einen realitätsgetreuen Grundsstückspreis an.';
  }

  return errors;
}
